<script setup lang="ts">
// TODO determine why the lack of Dialog import causes a TS error
import Dialog from 'primevue/dialog'
import { storeToRefs } from 'pinia'
import { EContent } from 'auth/enums/dialog-contents'
import type { PrimevueButton } from '@/types/primevue-button'
import IconUser from '@/assets/images/user.svg'
import IconKeyBold from '@/assets/images/key-bold.svg'

const authDialogDropdownRef = ref<Dialog>()
const signInTriggerRef = ref<PrimevueButton | null>(null)
const signUpTriggerRef = ref<PrimevueButton | null>(null)
const route = useRoute()
const authStore = useAuthStore()
const { isDialogVisible, dialogContent } = storeToRefs(authStore)
const closeDialog = () => {
  isDialogVisible.value = false
}
const moveFocusToTrigger = (content: EContent) => {
  const triggerRef =
    content === EContent.SIGN_IN || content === EContent.RECOVER_PASSWORD
      ? signInTriggerRef
      : signUpTriggerRef
  triggerRef.value?.$el?.focus()
}
const afterDialogHide = () => {
  const lastContentType = authStore.dialogContent
  authStore.switchContent(EContent.SIGN_IN)
  moveFocusToTrigger(lastContentType)
}
const openSignUpDialog = () => {
  isDialogVisible.value = true
  authStore.switchContent(
    route.name !== 'become-a-creator-page'
      ? EContent.SIGN_UP_FIRST_BRAND
      : EContent.SIGN_UP_FIRST_CREATOR,
  )
}

if (route.query['reset-token']) {
  isDialogVisible.value = true
  authStore.switchContent(EContent.SET_NEW_PASSWORD)
}
if (route.query['email-token']) {
  isDialogVisible.value = true
  authStore.switchContent(EContent.SIGN_UP_CONFIRM)
}
if (route.query.redirect) {
  isDialogVisible.value = true
}

defineExpose({ authDialogDropdownRef })
</script>

<template>
  <div>
    <div class="divide-y divide-solid divide-gray-500 md:flex md:divide-none">
      <Button
        ref="signUpTriggerRef"
        id-cy="sign-up-btn"
        class="p-button-text p-button-menu block h-10 w-full px-4 py-0 text-left text-base font-normal hover:text-blue-200 md:inline-flex md:h-[38px] md:w-auto md:px-3 md:py-2 md:text-center md:text-sm md:font-medium"
        aria-haspopup="dialog"
        :aria-expanded="authStore.isDialogVisible"
        @click="openSignUpDialog"
      >
        <IconUser class="user-icon mr-2.5 hidden md:block" />
        <span>Sign Up</span>
      </Button>
      <Button
        ref="signInTriggerRef"
        id-cy="sign-in-btn"
        class="p-button-text p-button-menu block h-10 w-full rounded-t-none px-4 py-0 text-left text-base font-normal hover:text-blue-200 md:inline-flex md:h-[38px] md:w-auto md:rounded-t md:px-3 md:py-2 md:text-center md:text-sm md:font-medium"
        aria-haspopup="dialog"
        :aria-expanded="authStore.isDialogVisible"
        @click="authStore.isDialogVisible = true"
      >
        <IconKeyBold class="hidden md:mr-2.5 md:block" />
        <span>Log In</span>
      </Button>
    </div>
    <Dialog
      ref="authDialogDropdownRef"
      v-model:visible="authStore.isDialogVisible"
      :breakpoints="{ '420px': '90vw' }"
      :style="{ width: '391px' }"
      class="[&>.p-dialog-header]:justify-end [&>.p-dialog-header]:border-0 [&>.p-dialog-header]:p-2"
      content-class="!pb-4"
      modal
      dismissable-mask
      :draggable="false"
      @after-hide="afterDialogHide"
    >
      <div
        autofocus
        tabindex="0"
        aria-label="dialog content"
        class="h-full w-full !shadow-none !outline-none"
      >
        <LazyAuthViewSignIn
          v-if="dialogContent === EContent.SIGN_IN"
          @switch="authStore.switchContent"
        />
        <LazyAuthViewSignUpFirst
          v-else-if="
            dialogContent === EContent.SIGN_UP_FIRST_BRAND ||
            dialogContent === EContent.SIGN_UP_FIRST_CREATOR
          "
          :type="
            dialogContent === EContent.SIGN_UP_FIRST_CREATOR
              ? 'creator'
              : 'brand'
          "
          @switch="authStore.switchContent"
        />
        <LazyAuthViewSignUpSecond
          v-else-if="
            dialogContent === EContent.SIGN_UP_SECOND_BRAND ||
            dialogContent === EContent.SIGN_UP_SECOND_CREATOR
          "
          :type="
            dialogContent === EContent.SIGN_UP_SECOND_CREATOR
              ? 'creator'
              : 'brand'
          "
          @switch="authStore.switchContent"
        />
        <LazyAuthViewSignUpSuccess
          v-else-if="dialogContent === EContent.SIGN_UP_SUCCESS"
          @close="closeDialog"
        />
        <LazyAuthViewSignUpConfirm
          v-else-if="dialogContent === EContent.SIGN_UP_CONFIRM"
          @switch="authStore.switchContent"
          @close="closeDialog"
        />
        <LazyAuthViewRecoverPassword
          v-else-if="dialogContent === EContent.RECOVER_PASSWORD"
          @switch="authStore.switchContent"
        />
        <LazyAuthViewRecoverPasswordConfirm
          v-else-if="dialogContent === EContent.RECOVER_PASSWORD_CONFIRM"
          @close="closeDialog"
        />
        <LazyAuthViewSetNewPassword
          v-else-if="dialogContent === EContent.SET_NEW_PASSWORD"
          @close="closeDialog"
        />
      </div>
    </Dialog>
  </div>
</template>

<style scoped>
.user-icon {
  @apply md:fill-gray-200;
}
.p-button-menu:hover .user-icon {
  @apply md:fill-blue-200;
}
:deep(.key-bold-stroke) {
  @apply md:stroke-gray-200;
}
.p-button-menu:hover :deep(.key-bold-stroke) {
  @apply md:stroke-blue-200;
}
</style>
